export default class ChatService {
  public async sendMessage(userMessage: any, assistantId: any, thread_id: any) {
    const url =
      process.env.REACT_APP_IS_PROD !== "yes"
        ? process.env.REACT_APP_API_DEV
        : process.env.REACT_APP_API_PROD;

    return await fetch(`${url}v1/external/assistant/sendMessage`, {
      method: "POST",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        AssistantId: assistantId,
        Message: userMessage,
        ThreadId: thread_id ?? null,
      }),
    });
  }

  public async getWidgetConfiguration(drastikAssistantId: any) {
    const url = `${
      process.env.REACT_APP_IS_PROD !== "yes"
        ? process.env.REACT_APP_API_DEV
        : process.env.REACT_APP_API_PROD
    }${
      process.env.REACT_APP_GET_WIDGET_CHAT_CONFIGURATION
    }?drastikAssistantId=${drastikAssistantId}`;

    return await fetch(`${url}`, {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
  }

  public async getAssistantChatConfiguration(drastikAssistantId: any) {
    const url = `${
      process.env.REACT_APP_IS_PROD !== "yes"
        ? process.env.REACT_APP_API_DEV
        : process.env.REACT_APP_API_PROD
    }${
      process.env.REACT_APP_GET_ASSISTANT_CHAT_CONFIGURATION
    }?drastikAssistantId=${drastikAssistantId}`;

    return await fetch(`${url}`, {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
  }
}
